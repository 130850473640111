import * as React from "react";
import type { ActionFunctionArgs, HeadersFunction, LoaderFunctionArgs, MetaFunction } from "~/remix";
import { Form, Link, json, redirect, useActionData, useLoaderData, useSearchParams } from "~/remix";

import InputField from "~/components/Form/InputField";
import { TwitchLoginLink } from "~/components/Icons/twitch";
import { attachTwitchUserToUser, getTwitchBasicAuthLink } from "~/helpers/twitch.server";
import _ from "~/helpers/utils";
import { verifyLogin } from "~/models/user.server";
import { createUserSession, getSession, getUserId } from "~/session.server";
import { safeRedirect } from "~/utils";
import BaseLayout from "./__layout";
import { InviteModeButton } from ".";

export const headers: HeadersFunction = () => {
  return {
    'Cache-Control': 'public, max-age=3600',
  };
};

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await getUserId(request);
  if (userId) return redirect("/");
  const session = await getSession(request);
  const state = session.setState(Date.now())
  const hasTwitchUser = session.getTwitchUser() !== undefined;
  return json({
    twitchAuthLink: getTwitchBasicAuthLink(state),
    hasTwitchUser,

  }, await session.commitSessionHeader());
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get("email");
  const password = formData.get("password");
  const remember = formData.get("remember");
  const session = await getSession(request);

  const sessionRedirectTo = session.getRedirectTo() ?? "/dashboard";
  const redirectTo = safeRedirect(formData.get("redirectTo"), sessionRedirectTo);

  if (typeof email !== "string" || email.length === 0) {
    return json(
      { errors: { email: "Identifiant obligatoire", password: undefined } },
      { status: 400 }
    );
  }

  if (typeof password !== "string" || password.length === 0) {
    return json(
      { errors: { email: null, password: "Mot de passe requis" } },
      { status: 400 }
    );
  }

  if (!_.isDev() && password.length < 8) {
    return json(
      { errors: { email: null, password: "Un peu trop court..." } },
      { status: 400 }
    );
  }

  const user = await verifyLogin(email, password);

  if (!user) {
    return json(
      { errors: { email: "Non, ça ne passe pas", password: null } },
      { status: 400 }
    );
  }

  const twitchUser = session.getTwitchUser();
  if (twitchUser) {
    await attachTwitchUserToUser(user, twitchUser);
  }

  // await registerUserConnection(user, request);

  return createUserSession({
    request,
    userId: user.id,
    remember: remember === "on" ? true : false,
    redirectTo,
  });
}

export const meta: MetaFunction = () => {
  return [{
    title: "S'identifier",
  }];
};

export function CommonFormLayout({ children, afterChild }: React.PropsWithChildren<{
  afterChild?: React.ReactElement
}>) {
  return <div className="flex min-h-full flex-col justify-center">
    <div className="mx-auto max-w-md w-96 px-8">
      <Form method="post" className="space-y-4">
        {children}
      </Form>
      {afterChild}
    </div>
  </div>
}

export const SubmitButton = ({ children }: React.PropsWithChildren<any>) => {
  return <button
    type="submit"
    className="w-full btn bg-blue-500 h-11 py-2 px-4 text-white hover:bg-blue-600 focus:bg-blue-600"
  >
    {children}
  </button>
}

export const TwitchLoginLinkButton = () => {
  const { twitchAuthLink, hasTwitchUser } = useLoaderData<typeof loader>();
  return <TwitchLoginLink href={twitchAuthLink} disabled={hasTwitchUser} className="? h-11" />
}

function LoginPage() {
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo") || "/dashboard";
  const actionData = useActionData<typeof action>();
  const emailRef = React.useRef<HTMLInputElement>(null);
  const passwordRef = React.useRef<HTMLInputElement>(null);


  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.password) {
      passwordRef.current?.focus();
    }
  }, [actionData]);

  const handleChangeCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target: {
      checked
    } } = e;
    document.cookie = `rm=${checked}; path=/`;
  }

  return (
    <CommonFormLayout
      afterChild={<InviteModeButton />}
    >
      <InputField
        layout="block"
        label="Identifiant/Email"
        name="email"
        id="email"
        ref={emailRef}
        required
        autoComplete="email"
        autoFocus={true}
        className='? !rounded-md'
      />
      <InputField
        layout="block"
        label="Mot de passe"
        name="password"
        ref={passwordRef}
        type="password"
        required
        autoComplete="off"
        autoFocus={false}
        className='? !rounded-md'
      />
      <input type="hidden" name="redirectTo" value={redirectTo} />
      <SubmitButton>S'identifier</SubmitButton>

      <TwitchLoginLinkButton />

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember"
            name="remember"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onChangeCapture={handleChangeCapture}
          />
          <label
            htmlFor="remember"
            className="ml-2 block label"
          >
            <span className="label-text">Se souvenir</span>
          </label>
        </div>
        <div className="text-center text-sm label-text">
          Pas encore inscrit?{" "}
          <Link
            className="text-blue-500 underline"
            to={{
              pathname: "/join",
              search: searchParams.toString(),
            }}
          >
            Par ici
          </Link>
        </div>
      </div>
    </CommonFormLayout>
  );
}

export default function LoginIndex() {
  return <BaseLayout>
    <LoginPage />
  </BaseLayout>
}